import Vue from 'vue';
import Vuex from 'vuex';
import createRersistedState from 'vuex-persistedstate';
import getters from './getters';
import user from './modules/user';
import chat from './modules/chat';

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins:[createRersistedState()],
  getters,
  modules: {
    user,
    chat,
  },
})

export default store