import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import "./assets/css/reset.css";
import "../public/static/flexible.js";

Vue.use(Vant);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
// 解决部分手机键盘遮挡输入框
window.addEventListener("resize", function() {
  if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("Linux") > -1) {
    // Android
    if (document.activeElement.tagName == "INPUT") {
      setTimeout(function() {
        var top = document.activeElement.getBoundingClientRect().top;
        window.scrollTo(0, top);
      }, 0)
    }
  }
  // if (
  //   document.activeElement.tagName == "INPUT" ||
  //   document.activeElement.tagName == "TEXTAREA"
  // ) {
  //   window.setTimeout(function() {
  //     document.activeElement.scrollIntoView(true);
  //     document.activeElement.scrollIntoViewIfNeeded();
  //   }, 300);
  // }
});
