// 关闭注册
export function closeFrame() {
  document.getElementsByClassName('pop-up-frame')[0].style.display = 'none';
  document.getElementsByClassName('van-overlay')[0].style.display = 'none';
}

// 开启注册
export function openFrame() {
  document.getElementsByClassName('pop-up-frame')[0].style.display = 'block';
  document.getElementsByClassName('van-overlay')[0].style.display = 'block';
}

// 微信授权获取code
export function getUrlCode() {
  var url = window.location.search;
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return theRequest;
}