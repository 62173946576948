import axios from '../index';
// const { get, post, put, del } = axios;
const { get, post, put } = axios;
import config from '../config';
const { relApi } = config;

// 微信登录 - 通过code获取token
export function getOpenid(data){
  return post(`${relApi}/callback/bebot/code/`, data);
}

export function getVipEexpiry(){
  let broker_id = localStorage.getItem('broker_id');
  return get(`${relApi}/vip_expiry/${broker_id}/`)
}

// 发送验证码
export function sendAuthSms(data){
  return post(`${relApi}/authSms/`, data)
}

// 个人主页
export function getUserInfo(param) {
  let broker_id = localStorage.getItem('broker_id');
  return get(`${relApi}/userInfo/${broker_id}/`, param);
}

// 绑定 | 修改手机号
export function addMobile(data) {
  let broker_id = localStorage.getItem('broker_id');
  let newData = {
    broker_id,
     ...data
  }
  return put(`${relApi}/user/add_mobile/${broker_id}/`, newData);
}

// 上传头像
export function setPicHead(broker_id, data) {
  return put(`${relApi}/user/head_portrait/${broker_id}/`, data)
}

// 修改昵称或者性别
export function changeUserInfo(broker_id, data) {
  return put(`${relApi}/user/update_info/${broker_id}/`, data)
}















// 获取图片验证码
// export function getImageCode(data) {
//   return get(`${relApi}/common/imageCode/`, data);
// }

// 输入验证码发送短信
export function sendSmsCode(data) {
  return post(`${relApi}/common/smsCode/`, data);
}

// 手机号是否已经注册
export function getMobileCount(data) {
  return post(`${relApi}/common/mobileCount/`, data);
}

// 用户注册接口
export function setUsersReg(data) {
  return post(`${relApi}/users/`, data);
}

// 用户登录接口
export function setPwdLogin(data) {
  return post(`${relApi}/authorizations/`, data);
}

// 修改密码
export function setPassword(data) {
  return post(`${relApi}/password/`, data);
}
