let relApi = '';
let jybApi = "https://test-gw.baoxianxia.com.cn"; // 鲸云保 api
let jybDomain = ''
switch (process.env.NODE_ENV) {
    case 'development': 
        relApi = 'https://test-bebotpro-api.baoxianxia.com.cn';//开发环境
        jybApi = "https://test-gw.zrbx.com";
        jybDomain = 'https://m.zrbx.com'
        break;
    case 'testing': 
        relApi = "https://test-bebotpro-api.baoxianxia.com.cn"; 
        jybApi = "https://test-gw.zrbx.com";
        jybDomain = 'https://m.zrbx.com'
        break;//测试环境
    default: 
        relApi = "https://api-bebot.zrbx.com"; //线上环境
        jybApi = "https://pantheon.zrbx.com";
        jybDomain = 'https://sparta.zrbx.com'
        break;
    // default: relApi = "https://test-bebotpro-api.baoxianxia.com.cn"; break;
}

export default {
    relApi,
    jybApi,
    jybDomain
}
