
const Home = resolve => require(['@/views/Home'], resolve);//首页

const KnowledgeBase = resolve => require(['@/views/KnowledgeBase'], resolve);//知识库
const KnowledgeBaseList = resolve => require(['@/views/KnowledgeBase/List.vue'], resolve);//知识库列表
const KnowledgeBaseDetail = resolve => require(['@/views/KnowledgeBase/Detail.vue'], resolve);//知识库详情
const KnowledgeBaseEdit = resolve => require(['@/views/KnowledgeBase/Edit.vue'], resolve);//知识库编辑

const Chat = resolve => require(['@/views/Chat'], resolve);//对话
const NowChat = resolve => require(['@/views/Chat/NowChat.vue'], resolve);//对话页
const HeBaoChat = resolve => require(['@/views/Chat/HeBaoChat.vue'], resolve);//核保对话页
const QuestionChat = resolve => require(['@/views/Chat/QuestionChat.vue'], resolve);//常见问题对话页
const HistoryChat = resolve => require(['@/views/Chat/HistoryChat.vue'], resolve);//历史记录页
const recommendProduct = resolve => require(['@/views/Chat/recommendProduct.vue'], resolve);//推荐产品页

const RegisterAndLogin = resolve => require(['@/views/RegisterAndLogin'], resolve);//登陆注册
const Register = resolve => require(['@/views/RegisterAndLogin/Register.vue'], resolve);//注册
const PwdLogin = resolve => require(['@/views/RegisterAndLogin/PwdLogin.vue'], resolve);//密码登录
const CodeLogin = resolve => require(['@/views/RegisterAndLogin/CodeLogin.vue'], resolve);//验证码登录

const PersonalCenter = resolve => require(['@/views/PersonalCenter'], resolve);//个人中心
const PersonalHome = resolve => require(['@/views/PersonalCenter/PersonalHome.vue'], resolve);//个人中心页
const ChangePhone = resolve => require(['@/views/PersonalCenter/ChangePhone.vue'], resolve);//修改手机号
const ChangePassword = resolve => require(['@/views/PersonalCenter/ChangePassword.vue'], resolve);//修改密码
const AgentVerification = resolve => require(['@/views/PersonalCenter/AgentVerification.vue'], resolve);//代理人验证

export default {
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        title: '首页',
      }
    },
    {
      path: '/diseaseList',
      name: 'diseaseList',
      component: () => import('../views/disease/diseaseList.vue'),
    },
    {
      path: '/guaranteeSlip',
      name: 'guaranteeSlip',
      component: () => import('../views/insurancePolicy/guaranteeSlip.vue'),
      meta: {
        title: '保单识别'
      },
    },
    {
      path: '/chat',
      name: 'chat',
      component: Chat,
      meta: {
        title: '对话页',
        keepAlive: true // 需要被缓存
      },
      children: [{
        path: '/chat/moment',
        name: 'nowChat',
        component: NowChat,
        meta: {
          title: '当前对话页',
        }
      }, 
      {
        path: '/chat/HeBaoChat',
        name: 'HeBaoChat',
        component: HeBaoChat,
        meta: {
          title: '核保模式',
        }
      }, 
      {
        path: '/chat/QuestionChat',
        name: 'QuestionChat',
        component: QuestionChat,
        meta: {
          title: '常见问题',
        }
      },
      {
        path: '/chat/history',
        name: 'historyChat',
        component: HistoryChat,
        meta: {
          title: '历史对话',
        }
      },]
    },
    {
      path: '/recommendProduct/:id',
      name: 'recommendProduct',
      component: recommendProduct,
      meta: {
        title: '产品推荐'
      }
    },
    {
      path: '/knowledge-base',
      name: 'KnowledgeBase',
      meta: {
        title: '知识库',
      },
      component: KnowledgeBase,
      redirect: '/knowledge-base/list',
      children: [{
        path: '/knowledge-base/list',
        name: 'KnowledgeBaseList',
        component: KnowledgeBaseList,
        meta: {
          title: '知识库列表',
        },
      }, {
        path: '/knowledge-base/detail/:id',
        name: 'KnowledgeBaseDetail',
        component: KnowledgeBaseDetail,
        meta: {
          title: '知识库详情',
        },
      }, {
        path: '/knowledge-base/edit/:id',
        name: 'KnowledgeBaseEdit',
        component: KnowledgeBaseEdit,
        meta: {
          title: '知识库编辑',
        },
      },]
    },
    {
      path: '/register-login',
      name: 'RegisterAndLogin',
      redirect: '/register-login/codeLogin',
      component: RegisterAndLogin,
      meta: {
        title: '登陆注册',
      },
      children: [{
        path: '/dialog-login/dialog',
        name: 'Register',
        title: '注册',
        component: Register,
        meta: {
          title: '注册',
        },
      }, {
        path: '/register-login/pwdLogin',
        name: 'PwdLogin',
        component: PwdLogin,
        meta: {
          title: '密码登录',
        },
      }, {
        path: '/register-login/codeLogin',
        name: 'CodeLogin',
        component: CodeLogin,
        meta: {
          title: '验证码登录',
        },
      }]
    },
    {
      path: '/personal',
      name: 'PersonalCenter',
      component: PersonalCenter,
      meta: {
        title: '个人中心',
      },
      children: [{
        path: '/personal/personalHome',
        name: 'PersonalHome',
        component: PersonalHome,
        meta: {
          title: '个人中心',
        },
      }, {
        path: '/personal/changePhone/:type',
        name: 'ChangePhone',
        component: ChangePhone,
        meta: {
          title: '修改手机号',
        },
      }, {
        path: '/personal/changePassword',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
          title: '修改密码',
        },
      }, {
        path: '/personal/agentVerification',
        name: 'AgentVerification',
        component: AgentVerification,
        meta: {
          title: '代理人验证',
        },
      }]
    }
  ]
}