const initState = {
  guideHomeFlag: true,//首页引导
  guideEditTagFlag: true,//编辑页标签引导
  guideEditTagManageFlag: true,//编辑页标签管理引导
  userInfo: null,
  broker_id: null,
}

const list = {
  state: {
    ...initState
  },
  mutations: {
    SET_GUIDE_FLAG(state, payload) {
      switch (payload.type) {
        case 'guideHomeFlag': state.guideHomeFlag = payload.data; break;
        case 'guideEditTagFlag': state.guideEditTagFlag = payload.data; break;
        case 'guideEditTagManageFlag': state.guideEditTagManageFlag = payload.data; break;
      }
    },
    SET_USERINFO(state, payload){
     state.userInfo = payload;
    },
    SET_BROKER_ID(state, payload){
      state.broker_id = payload;
    },
    CHANGE_USEFINFO(state, payload){
      if(payload.type=='sex'){
        state.userInfo.gender = payload.data;
      }
      if(payload.type=='name'){
        state.userInfo.nick_name = payload.data;
      }
    }
  },
  actions: {
  }
}

export default list;