<template>
  <div>
    <keep-alive :include="keepAlive">
      <router-view v-if="!hideView" />
    </keep-alive>
  </div>
</template>

<script>
// import { getOpenid, getVipEexpiry } from "@/fetch/api/login";
import { getUserInfo, getOpenid } from "@/fetch/api/login";
import { mapState, mapMutations } from "vuex";
import { getUrlCode } from "@/utils/validate";
export default {
  name: "App",
  components: {},
  data() {
    return {
      // includeHash: window.location.hash.includes("#/personal/changePhone"),
      includeHash: window.location.hash.includes("/personal/changePhone"),
      hideView: false,
      broker_id: null,
    };
  },
  computed: {
    ...mapState({
      keepAlive: (state) => state.chat.keepAlive,
    }),
  },
  methods: {
    ...mapMutations(["SET_USERINFO"]),
    // 获取url参数方法
    getUrlParam(sUrl) {
      let leftUrlVal = sUrl.indexOf("?") + 1;
      let parasString = sUrl.slice(leftUrlVal);
      let paras = parasString.split("&");
      let parasjson = {};
      paras.forEach((value) => {
        var urlVal = value.split("=");
        parasjson[urlVal[0]]
          ? (parasjson[urlVal[0]] = [].concat(parasjson[urlVal[0]], urlVal[1]))
          : (parasjson[urlVal[0]] = urlVal[1]);
      });

      let result =
        arguments[1] !== void 0 ? parasjson[arguments[1]] || "" : parasjson;
      return result;
    },
    getUserInfoData() {
      getUserInfo({
        broker_id: this.broker_id,
      }).then((res) => {
        if (res.code == 200) {
          this.SET_USERINFO(res.result);
        }
      });
    },
    getCode() {
      const code = getUrlCode().code;
      var local = window.location.href;
      var appid =
        process.env.NODE_ENV === "development"
          ? "wx67dbdad99e45d439"
          : "wx026553ce8b4e59a3";
      if (code == null || code === "") {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      } else {
        // localStorage.setItem("bindPhoneNumberVisible", false);
        this.getUserOpenid({ code: code })
      }
    },
    getUserOpenid(data){
      getOpenid(data).then((res) => {
        this.hideView = false
        if (res.code == 200) {
          let token = res.result.token;
          let broker_id = res.result.broker_id;
          let jyb_id = res.result.jyb_id;

          this.broker_id = broker_id;
          localStorage.setItem("token", token);
          localStorage.setItem("jyb_id", jyb_id);   // 鲸云保userId
          localStorage.setItem("broker_id", broker_id);
          this.getUserInfoData();
        }
      }).finally(() => {
        this.hideView = false
      })
    }
  },
  created() {
    let urlData = this.getUrlParam(window.document.location.href)
    this.hideView = true
    if (urlData.from) {
      delete urlData.from
    }
    if (urlData.app_token) {
      localStorage.setItem('app_token', urlData.app_token)
      delete urlData.app_token
    }
    if (urlData.from_id == 1) {
      localStorage.setItem("safe", 1);
      this.getUserOpenid({ ...urlData })
    } else {
      localStorage.setItem("safe", 2);
      this.getCode()
    }
  },
};
</script>

<style lang="scss" scope>
.prompt-box {
  width: 8.13rem;
  height: 4.61rem;
  background: #ffffff;
  padding: 0.53rem;
  &.van-popup {
    overflow-y: hidden;
  }
  h3 {
    font-size: 0.45rem;
    font-weight: 500;
    color: #333333;
    line-height: 0.64rem;
    text-align: center;
    margin-top: 1rem;
  }
  .go-reg {
    display: block;
    width: 40%;
    height: 1.12rem;
    background: #3971ff;
    border-radius: 0.56rem;
    font-size: 0.4rem;
    font-weight: 500;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 0.77rem;
  }
  .reminder {
    font-size: 0.45rem;
    font-weight: 400;
    color: #666666;
    text-align: center;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.4rem;
    .sure {
      width: 2.53rem;
      height: 1.12rem;
      border-radius: 0.56rem;
      border: 0.03rem solid #cccccc;
      margin-top: 0.8rem;
      font-size: 0.4rem;
      font-weight: 400;
      color: #666666;
    }
    .cancel {
      width: 2.53rem;
      height: 1.12rem;
      background: #3971ff;
      border-radius: 0.56rem;
      margin-top: 0.8rem;
      font-size: 0.4rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
