
const initState = {
  nowChatList: [],
  keepAlive: [],
  heBaoList:[],
  // chatMode: 0,//0普通,1智能
  questionChatList: [],
}


function addDialog(dialog_list, payload){
  if(payload.data.content && payload.data.content.common_disease){
    payload.data = Object.assign({status:true},payload.data);
  }
  if(payload.data && payload.data.diseaseCode && payload.data.diseaseCode.length>0){
    dialog_list = dialog_list.map(each =>{
      if(each.status){
        each.status = false;
      }
      return each;
    })
  }
  dialog_list.push(payload.data)
}


function changeDialog(dialog_list, payload){
  dialog_list.forEach((item,index)=>{
    if(item.sentence_id == payload.data.sentence_id){
      dialog_list[index].is_collected = '1';
      dialog_list[index].online_data_id = payload.data.online_data_id;
    }
  })
}

const list = {
  state: {
    ...initState,
  },
  mutations: {
    SET_HeBao_LIST(state, payload){ //智能核保对话列表
      // 清空本次对话
      if (payload.type == 'clear') {
        state.heBaoList = []
        state.chatMode = 1;
        return;
      }
      if (payload.type == 'add') {
        addDialog(state.heBaoList, payload)
        return;
      }
      if (payload.type == 'change') {
        changeDialog(state.heBaoList, payload)
        return;
      }
    },
    SET_CHAT_LIST(state, payload) { //智能问答对话列表
      // 清空本次对话
      if (payload.type == 'clear') {
        state.nowChatList = []
        state.chatMode = 0;
        return;
      }
      if (payload.type == 'add') {
        addDialog(state.nowChatList, payload)
        return;
      }
      if (payload.type == 'assignment') {
        state.nowChatList = payload.data;
        return;
      }
      if(payload.type == 'evaluate'){
        state.nowChatList.forEach((item,index)=>{
          if(item.sentence_id == payload.data.sentence_id){
            state.nowChatList[index].assess = payload.data.agree_oppose;
          }
        })
        return
      }
      if (payload.type == 'change') {
        changeDialog(state.heBaoList, payload)
        return;
      }
    },
    SET_QUESTION_CHAT_LIST(state, payload) {  //常见问题对话列表
      // 清空本次对话
      if (payload.type == 'clear') {
        state.questionChatList = []
        return;
      }
      if (payload.type == 'add') {
        addDialog(state.questionChatList, payload)
        return;
      }
      if (payload.type == 'change') {
        changeDialog(state.questionChatList, payload)
        return;
      }
    },
    SET_CHAT_MODE(state, payload) {
      state.chatMode = payload;
    },
    SET_KEEP_ALIVE(state, payload) {
      if(payload.type=='add'){
        state.keepAlive=payload.data
      }
      if(payload.type=='delete'){
        state.keepAlive=payload.data
      }
    }
  },
  actions: {
  }
}

export default list;