import axios from 'axios';
import { Local } from '@/utils/local.js';

axios.defaults.timeout = 60000;
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';

axios.interceptors.request.use(
  config => {
    if(!config.url.includes('/callback/bebot/code/') && !config.url.includes('/should/know')){
      const token = localStorage.getItem('token') ? 'JWT ' + localStorage.getItem('token') : null;
      token ? config.headers.Authorization = token : null;
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.log(401);
          Local.clear();
          window.location.href = `${window.location.origin}/#/home`;
          // localStorage.setItem("bindPhoneNumberVisible", true);
          break;
        case 403:
          console.log(403)
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default {
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: url,
        params
      })
        .then(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  },

  getStream(url, params) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: url,
        params
      })
        .then(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  },


  post(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: url,
        // data: qs.stringify(data)
        data
      })
        .then(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  },

  put(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'put',
        url: url,
        data
      })
        .then(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  },

  del(url, params) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'delete',
        url: url,
        params
      })
        .then(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  },

  patch(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'patch',
        url: url,
        // data: qs.stringify(data)
        data
      })
        .then(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  },
}
