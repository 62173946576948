import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from '@/store';


Vue.use(Router);

const router = new Router(routes)

router.beforeEach((to, from, next) => {
  // console.log(to,from,'routerrouterrouter');
  // const title = to.meta && to.meta.title;
  // 不缓存对话页
  if(to.name=='home'){
    store.commit('SET_KEEP_ALIVE', {type:'delete',data:[]});
    store.commit('SET_CHAT_LIST', {type:'clear',data:[]});
    store.commit('SET_QUESTION_CHAT_LIST', {type:'clear',data:[]});
  }
  // 缓存对话页
  if(to.name=='nowChat'||to.name=='historyChat'||to.name=='KnowledgeBaseEdit'){
    store.commit('SET_KEEP_ALIVE', {type:'add',data:['chat']});
  }

  // doc-title
  if(to.path == '/personal/changePhone/add'){
    document.title = '绑定手机号';
  }else{
    document.title = process.env.NODE_ENV === 'development'?'必保机器人':'BEBOT精灵';
  }

  next();
});

export default router;